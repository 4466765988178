import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, Row, Col, Form, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const TimeOffLedger = () => {
  const [ledger, setLedger] = useState([]);
  const [id_karyawan, setIdKaryawan] = useState(0);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [karyawan, setKaryawan] = useState([]);
  const [akses, setAkses] = useState(false);

  useEffect(() => {
    Axios.get("/karyawan/options")
      .then((response) => {
        setKaryawan(response.data.data);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });

    function checkPermission() {
      const user_permission = localStorage.getItem("user_permission");
      if (user_permission.includes("Time Management")) {
        setAkses(true);
      } else {
        setIdKaryawan(localStorage.getItem("id_user"));
      }
    }

    checkPermission();
  }, []);

  function loadTable() {
    if (id_karyawan === 0) {
      swal("error", `Please select employee!`, "error");
      return;
    }

    const ledger = {
      tahun: tahun,
      id_karyawan: id_karyawan,
    };

    Axios.post(`/jurnal_cuti/ledger`, ledger)
      .then((response) => {
        setLedger(response.data.data);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Filter</Card.Header>
          <Card.Body>
            <Row>
              <Col md="8">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Employee
                  </Form.Label>
                  <Col sm={5}>
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                      disabled={!akses}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col sm={2}>
                    <Form.Control
                      type="number"
                      size="sm"
                      name="tahun"
                      value={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Form.Group>
              </Col>

              <Col md="4">
                <Button variant="primary" onClick={loadTable} size="sm">
                  Get Data
                </Button>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {ledger.map((dt, index) => (
          <Card className="mt-3" key={index}>
            <Card.Header>
              <div style={{ fontSize: 16, fontWeight: "bold" }}>{dt.jenis_cuti}</div>
            </Card.Header>
            <Card.Body>
              <Table
                responsive
                striped
                bordered
                hover
                size="sm"
                className="compact"
                style={{ fontSize: 14 }}
              >
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th style={{ textAlign: "right" }}>Duration</th>
                  </tr>
                </thead>
                <tbody>
                  {dt.detail.map((dt2, index2) => (
                    <tr key={index2}>
                      <td>{dt2.tanggal}</td>
                      <td>{dt2.keterangan}</td>
                      <td style={{ textAlign: "right" }}>{dt2.durasi}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <div style={{ fontSize: 14, float: "right", fontWeight: "bold", color: "red" }}>
                Balance {dt.jenis_cuti} : {dt.balance}
              </div>
            </Card.Footer>
          </Card>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default TimeOffLedger;
