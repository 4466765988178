import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import validator from "validator";
import Axios from "../../myAxios";
import { mySqlDate } from "../../helper";

const LiburCreate = () => {
  const [tanggal_libur, setTanggalLibur] = useState(mySqlDate(new Date()));
  const [nama_libur, setNamaLibur] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Time Management")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  function Submit() {
    if (!validator.isEmpty(nama_libur)) {
      const libur = {
        tanggal_libur: tanggal_libur,
        nama_libur: nama_libur,
      };

      Axios.post("/libur", libur)
        .then((response) => {
          if (response.data.success) {
            navigate("/libur");
          } else {
            swal("Something went wrong", `An error occured while saving this data`, "error");
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      swal("Warning!", "Please fill in all the required fields!", "error");
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-3">
          <Card.Header as="h5">Create Public Holiday</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Date</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tanggal_libur"
                      value={tanggal_libur}
                      onChange={(e) => setTanggalLibur(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Name</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="nama_libur"
                      value={nama_libur}
                      onChange={(e) => setNamaLibur(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default LiburCreate;
