import "../../css/jquery.dataTables.css";
import React, { useState } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { useNavigate } from "react-router-dom";
import { mySqlDate } from "../../helper";
import validator from "validator";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const TravelLogCreate = () => {
  const [tanggal, setTanggal] = useState(mySqlDate(new Date()));
  const [jam_awal, setJamAwal] = useState("08:30");
  const [jam_akhir, setJamAkhir] = useState("09:30");
  const [verify, setVerify] = useState(false);
  const [keterangan, setKeterangan] = useState("");
  const [pesan, setPesan] = useState("");
  const [detail_cuti, setDetailCuti] = useState([]);
  const navigate = useNavigate();

  function changeTanggal(value) {
    setVerify(false);
    setTanggal(value);
  }

  function calculate() {
    const cuti = {
      id_karyawan: localStorage.getItem("id_user"),
      tanggal_awal: tanggal,
      tanggal_akhir: tanggal,
    };

    Axios.post(`/jurnal_cuti/calculate_absence`, cuti)
      .then((response) => {
        let data = response.data;
        console.log(data);
        setDetailCuti(data.data);
        setPesan(data.message);
        setVerify(data.data.length > 0 ? true : false);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });
  }

  function Submit() {
    if (validator.isEmpty(keterangan)) {
      swal("error", `Please input description!`, "error");
      return;
    }

    if (!verify) {
      swal("error", `Please click verify button first!`, "error");
      return;
    }

    const cuti = {
      id_karyawan: localStorage.getItem("id_user"),
      nama_karyawan: localStorage.getItem("nama_karyawan"),
      tanggal_awal: tanggal,
      tanggal_akhir: tanggal,
      jam_awal: jam_awal,
      jam_akhir: jam_akhir,
      durasi: detail_cuti.length,
      keterangan: keterangan.replace(/\n\r?/g, "<br />"),
      status_cuti: 0,
      detail_cuti: detail_cuti,
      link: process.env.REACT_APP_BASE_URL,
    };

    Axios.post(`/travel_log`, cuti)
      .then((response) => {
        if (response.data.success) {
          navigate(-1);
        } else {
          swal("Something went wrong", `An error occured while saving this data`, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        swal("error", `Request failed: ${err}`, "error");
      });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Create Travel Log</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Date</Form.Label>
                  </Col>
                  <Col sm="3" xs="9" className="mb-2">
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tanggal"
                      value={tanggal}
                      onChange={(e) => changeTanggal(e.target.value)}
                    />
                  </Col>
                  <Col sm="1" xs="3">
                    <Button variant="primary" size="sm" onClick={calculate}>
                      Verify
                    </Button>
                  </Col>
                  <Col sm="4">
                    <span style={{ color: detail_cuti.length === 0 ? "red" : "black" }}>
                      {pesan}
                    </span>
                    <ul>
                      {detail_cuti.map((dt, index) => (
                        <li key={index}>{dt.long_tanggal}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Time</Form.Label>
                  </Col>
                  <Col sm="2" xs="5" className="mb-2">
                    <Form.Control
                      type="time"
                      size="sm"
                      value={jam_awal}
                      onChange={(e) => setJamAwal(e.target.value)}
                    />
                  </Col>
                  -
                  <Col sm="2" xs="5" className="mb-2">
                    <Form.Control
                      type="time"
                      size="sm"
                      value={jam_akhir}
                      onChange={(e) => setJamAkhir(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group>
                <Row>
                  <Col sm="2">
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col sm="4" className="mb-2">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="keterangan"
                      value={keterangan}
                      onChange={(e) => setKeterangan(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default TravelLogCreate;
