import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, Row, Col, Form, Button, Spinner } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import {} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const EmployeeAtt = () => {
  const [employeeAtt, setEmployeeAtt] = useState([]);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [id_bulan, setIdBulan] = useState(new Date().getMonth() + 1);
  const [id_karyawan, setIdKaryawan] = useState(0);
  const [karyawan, setKaryawan] = useState([]);
  const [counter, setCounter] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const bulan = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Time Management")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }

    Axios.get("/karyawan/options")
      .then((response) => {
        setKaryawan(response.data.data);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });
  }, [navigate]);

  useEffect(() => {
    async function loadTable() {
      setLoading(true);
      const params = {
        tahun: tahun,
        id_bulan: id_bulan,
        id_karyawan: id_karyawan,
      };
      await Axios.post("/attlog/employee_att", params)
        .then((response) => {
          setEmployeeAtt(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
      setLoading(false);
    }
    loadTable();
  }, [id_bulan, id_karyawan, tahun, counter]);

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Filter - Employee Attendance</Card.Header>
          <Card.Body>
            <Row>
              <Col md="2">
                <Form.Group as={Row}>
                  <Form.Label column sm={4} style={{ fontSize: 13 }}>
                    Year
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      required
                      type="number"
                      name="tahun"
                      size="sm"
                      defaultValue={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Month
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      name="id_bulan"
                      size="sm"
                      value={id_bulan}
                      onChange={(e) => setIdBulan(e.target.value)}
                    >
                      {bulan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Employee
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col md="2">
                {loading ? (
                  <Button variant="primary" size="sm" disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{" "}
                    Loading ...
                  </Button>
                ) : (
                  <Button variant="primary" size="sm" onClick={() => setCounter(counter + 1)}>
                    Refresh
                  </Button>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {employeeAtt.map((dt, index) => (
          <Card className="mt-3" key={index}>
            <Card.Header style={{ fontSize: 14, fontWeight: "bold" }}>{dt.karyawan}</Card.Header>
            <Card.Body>
              <Row>
                <Col md="9">
                  <Table responsive striped bordered hover size="sm" className="compact">
                    <thead>
                      <tr>
                        <td>Date</td>
                        <td>Scan Time</td>
                        <td>Note</td>
                      </tr>
                    </thead>
                    <tbody>
                      {dt.scan.map((dt2, index2) => (
                        <tr key={index2} className={dt2.style}>
                          <td style={{ fontSize: 12 }}>{dt2.tanggal}</td>
                          <td style={{ fontSize: 12 }}>
                            {dt2.item.map((dt3, index3) => (
                              <div key={index3}>{dt3.waktu}</div>
                            ))}
                          </td>
                          <td style={{ fontSize: 12 }}>
                            {dt2.item.map((dt3, index3) => (
                              <div key={index3}>
                                {dt3.keterangan.split("<br />").map((ket, id) => {
                                  return <div key={id}>{ket}</div>;
                                })}
                              </div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md="3">
                  <Table responsive striped bordered hover size="sm" className="compact">
                    <thead>
                      <tr>
                        <td style={{ fontSize: 12 }}>Description</td>
                        <td style={{ fontSize: 12 }}>Days</td>
                      </tr>
                    </thead>
                    <tbody>
                      {dt.summary.map((dt2, index2) => (
                        <tr key={index2} className={dt2.style}>
                          <td style={{ fontSize: 12 }}>{dt2.nama_jenis}</td>
                          <td style={{ fontSize: 12 }}>{dt2.hari}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default EmployeeAtt;
