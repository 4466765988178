import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import validator from "validator";
import Axios from "../../myAxios";

const UserPassword = () => {
  const [nama_karyawan, setNamaKaryawan] = useState("");
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Master")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadData() {
      await Axios.get(`/karyawan/${id}`)
        .then((response) => {
          let data = response.data.data;
          setNamaKaryawan(data.nama_karyawan);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadData();
  }, [id]);

  function fieldChange(e) {
    switch (e.target.name) {
      case "password":
        setPassword(e.target.value);
        break;
      default:
        setRepeat(e.target.value);
    }
  }

  function Submit() {
    if (!validator.isEmpty(password) && password === repeat) {
      Axios.put(`/karyawan/password/${id}`, { password: password })
        .then((response) => {
          if (response.data.success) {
            navigate(-1);
          } else {
            swal("Something went wrong", `An error occured while saving this data`, "error");
          }
          console.log(response.data.message);
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      swal(
        "Warning!",
        "Please fill in all the required fields or password did not match!",
        "error"
      );
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Change Password - {nama_karyawan}</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>New Password</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="password"
                      size="sm"
                      name="password"
                      value={password}
                      onChange={(e) => fieldChange(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Repeat Password</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="password"
                      size="sm"
                      name="repeat"
                      value={repeat}
                      onChange={(e) => fieldChange(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default UserPassword;
