import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { useParams } from "react-router-dom";
import LogoCom from "../../assets/company.png";
import TandaTangan from "../../assets/ttd.png";
import { page, row, left50, right50 } from "../../helper";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const LeaveApplicationPrint = () => {
  const [cuti, setCuti] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/public/jurnal_cuti/${id}`)
        .then((response) => {
          setCuti(response.data.data);
          setLoaded(true);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadTable();

    if (loaded) {
      setTimeout(() => {
        window.print();
      }, 500);
    }
  }, [id, loaded]);

  return (
    <div id="page-content-wrapper">
      <div className="container-fluid ">
        <div id="print">
          <div style={page}>
            <div style={row}>
              <div style={left50}>
                <img src={LogoCom} height={48} alt="Logo" />
              </div>
              <div style={right50}>
                <div
                  style={{ fontWeight: "bold", textAlign: "right", fontSize: 24, color: "#a3180e" }}
                >
                  LEAVE APPLICATION FORM
                </div>
              </div>
            </div>
            <div style={row} className="mt-4">
              <div style={left50}>
                <div style={{ fontWeight: "bold" }}>EMPLOYEE : {cuti.nama_karyawan}</div>
              </div>
              <div style={right50}>
                <div style={{ fontWeight: "bold", textAlign: "right" }}>DATE : {cuti.dibuat}</div>
              </div>
            </div>

            <div style={row}>
              <table width="100%" cellPadding="5">
                <thead>
                  <tr>
                    <td
                      colSpan={4}
                      style={{ textAlign: "center", border: "1px solid black", fontWeight: "bold" }}
                    >
                      DESCRIPTION
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", fontWeight: "bold" }}>Date</td>
                    <td style={{ border: "1px solid black", fontWeight: "bold" }}>Time</td>
                    <td style={{ border: "1px solid black", fontWeight: "bold" }}>No. Days</td>
                    <td style={{ border: "1px solid black", fontWeight: "bold" }}>Note</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid black" }}>
                      {cuti.tanggal_awal + " - " + cuti.tanggal_akhir}
                    </td>
                    <td style={{ border: "1px solid black" }}>
                      {cuti.jam_awal + " - " + cuti.jam_akhir}
                    </td>
                    <td style={{ border: "1px solid black" }}>{cuti.durasi}</td>
                    <td style={{ border: "1px solid black" }}>{cuti.note}</td>
                  </tr>
                  <tr>
                    <td colSpan={4} style={{ border: "1px solid black" }}>
                      <div style={{ fontWeight: "bold" }}>LEAVE TYPE : </div>
                      {cuti.nama_jenis}
                      <div style={{ fontWeight: "bold", marginTop: 10 }}>REASON : </div>
                      {cuti.keterangan}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div style={row}>
              <div style={{ marginLeft: 20 }}>
                <div className="mt-5 mb-2">Bagus Putra Wijaya</div>
                {parseInt(cuti.status_cuti) === 1 ? (
                  <img src={TandaTangan} height={48} alt="TTD" />
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div style={row}>
              <div style={left50}>
                <div style={{ marginLeft: 20 }}>
                  <div>Direktur</div>
                </div>
              </div>
              <div style={right50}>
                <div style={{ textAlign: "right" }}>{cuti.nama_karyawan}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeaveApplicationPrint;
