import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, Button, Dropdown, DropdownButton, ButtonGroup, Badge } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const Karyawan = () => {
  const [karyawan, setKaryawan] = useState([]);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Master")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get("/karyawan")
        .then((response) => {
          setKaryawan(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
      $(document).ready(function () {
        $("#example").DataTable();
      });
    }
    loadTable();
  }, [counter]);

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/karyawan/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter - 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  function activating(id) {
    Axios.put(`/karyawan/activating/${id}`).then((response) => {
      if (response.data.success) {
        swal("Delete Confirmation!", "Data updated successfully", "success");
        setCounter(counter + 1);
      }
    });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Employees</Card.Header>
          <Card.Body>
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              id="example"
              className="compact"
              style={{ minHeight: "200px" }}
            >
              <thead>
                <tr>
                  <th style={{ width: "30%" }}>Name</th>
                  <th>Email</th>
                  <th>Birth Date</th>
                  <th>Join Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {karyawan.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.nama_karyawan}</td>
                    <td>{dt.email}</td>
                    <td>{dt.long_tgl_lahir}</td>
                    <td>{dt.long_tgl_join}</td>
                    <td>
                      {dt.aktif === 0 ? (
                        <Badge bg="secondary">Not Active</Badge>
                      ) : (
                        <Badge bg="success">Active</Badge>
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DropdownButton as={ButtonGroup} variant="secondary" size="sm" title="">
                        <Dropdown.Item
                          style={{ fontSize: 14 }}
                          onClick={() => navigate(`/karyawan/permission/${dt.id}`)}
                        >
                          Permission
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ fontSize: 14 }}
                          onClick={() => navigate(`/karyawan/password/${dt.id}`)}
                        >
                          Change Password
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ fontSize: 14 }}
                          onClick={() => navigate(`/karyawan/edit/${dt.id}`)}
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item style={{ fontSize: 14 }} onClick={() => activating(dt.id)}>
                          {dt.aktif === 0 ? "Activate" : "Deactivate"}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          style={{ fontSize: 14 }}
                          onClick={(e) => confirmDelete(dt.id)}
                        >
                          Delete
                        </Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Button variant="primary" size="sm" onClick={() => navigate("/karyawan/pin")}>
                PIN
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Karyawan;
