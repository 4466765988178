import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";
import { mySqlDate } from "../../helper";

const KursEdit = () => {
  const [tanggal, setTanggal] = useState(mySqlDate(new Date()));
  const [jam, setJam] = useState(0);
  const [menit, setMenit] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [approved, setApproved] = useState(0);
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function loadData() {
      await Axios.get(`/lembur/${id}`)
        .then((response) => {
          let data = response.data.data;
          setTanggal(data.tanggal);
          setJam(data.jam);
          setMenit(data.menit);
          setKeterangan(data.keterangan);
          setApproved(data.approved);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadData();
  }, [id]);

  function fieldChange(e) {
    switch (e.target.name) {
      case "tanggal":
        setTanggal(e.target.value);
        break;
      case "jam":
        setJam(e.target.value);
        break;
      case "menit":
        setMenit(e.target.value);
        break;
      default:
        setKeterangan(e.target.value);
    }
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const lembur = {
        tanggal: tanggal,
        jam: jam,
        menit: menit,
        keterangan: keterangan,
        approved: approved,
      };

      Axios.put(`/lembur/${id}`, lembur)
        .then((response) => {
          if (response.data.success) {
            swal("Success!", "Your data has been updated", "success").then(() => {
              navigate(-1);
            });
          } else {
            swal("Something went wrong", response.data.message, "error");
          }
          console.log(response.data.message);
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    }
    setValidated(true);
  };

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Edit Overtime</Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tanggal"
                      value={tanggal}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Hour</Form.Label>
                    <Form.Control
                      type="number"
                      size="sm"
                      name="jam"
                      value={jam}
                      onChange={(e) => fieldChange(e)}
                      max="24"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Minute</Form.Label>
                    <Form.Control
                      type="number"
                      size="sm"
                      name="menit"
                      value={menit}
                      onChange={(e) => fieldChange(e)}
                      max="60"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="keterangan"
                      value={keterangan}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default KursEdit;
