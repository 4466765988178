import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import validator from "validator";
import Axios from "../../myAxios";

const PermissionEdit = () => {
  const [nama_permission, setNamaPermission] = useState("");
  const [keterangan, setKeterangan] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Permission")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadData() {
      await Axios.get(`/permission/${id}`)
        .then((response) => {
          let data = response.data.data;
          setNamaPermission(data.nama_permission);
          setKeterangan(data.keterangan);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadData();
  }, [id]);

  function fieldChange(e) {
    switch (e.target.name) {
      case "nama_permission":
        setNamaPermission(e.target.value);
        break;
      default:
        setKeterangan(e.target.value);
    }
  }

  function Submit() {
    if (!validator.isEmpty(nama_permission)) {
      const permission = {
        nama_permission: nama_permission,
        keterangan: keterangan,
      };

      Axios.put(`/permission/${id}`, permission)
        .then((response) => {
          if (response.data.success) {
            navigate("/permission");
          } else {
            swal("Something went wrong", `An error occured while saving this data`, "error");
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      swal("Warning!", "Please fill in all the required fields!", "error");
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Edit Permission</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Name</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="nama_permission"
                      value={nama_permission}
                      onChange={(e) => fieldChange(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="keterangan"
                      value={keterangan}
                      onChange={(e) => fieldChange(e)}
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default PermissionEdit;
