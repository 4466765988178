import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { useNavigate } from "react-router-dom";
import {
  Toggle2Off,
  CalendarPlus,
  CalendarRange,
  CalendarX,
  Fingerprint,
} from "react-bootstrap-icons";

const MenuTimeManagement = () => {
  const navigate = useNavigate();

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Row className="mt-3 ">
          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/jenis_cuti`)}
              >
                <Toggle2Off size={64} />
                <div className="mt-3" style={{ fontSize: 16 }}>
                  Leave Category
                </div>
              </Button>
            </div>
          </Col>
          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/time_off_balance`)}
              >
                <CalendarPlus size={64} />
                <div className="mt-3" style={{ fontSize: 15 }}>
                  Leave Balance
                </div>
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 ">
          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/absen`)}
              >
                <CalendarX size={64} />
                <div className="mt-3" style={{ fontSize: 15 }}>
                  Absence
                </div>
              </Button>
            </div>
          </Col>

          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/leave_request`)}
              >
                <CalendarRange size={64} />
                <div className="mt-3" style={{ fontSize: 15 }}>
                  Leave Request
                </div>
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3 ">
          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/attlog`)}
              >
                <Fingerprint size={64} />
                <div className="mt-3" style={{ fontSize: 15 }}>
                  Attendance Log
                </div>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default MenuTimeManagement;
