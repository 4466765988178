import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { useNavigate } from "react-router-dom";
import { ListNested, PersonLinesFill, CalendarX } from "react-bootstrap-icons";

const MenuMaster = () => {
  const navigate = useNavigate();
  const user_permission = localStorage.getItem("user_permission");

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Row className="mt-3 ">
          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/karyawan`)}
              >
                <PersonLinesFill size={64} />
                <div className="mt-3" style={{ fontSize: 16 }}>
                  Employee
                </div>
              </Button>
            </div>
          </Col>
          {user_permission.includes("Permission") ? (
            <Col sm="2">
              <div className="d-grid gap-2">
                <Button
                  variant="danger"
                  size="lg"
                  style={{ padding: 30, margin: 5, color: "white" }}
                  onClick={() => navigate(`/permission`)}
                >
                  <ListNested size={64} />
                  <div className="mt-3" style={{ fontSize: 16 }}>
                    Permission
                  </div>
                </Button>
              </div>
            </Col>
          ) : (
            <></>
          )}
          <Col sm="2">
            <div className="d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                style={{ padding: 30, margin: 5, color: "white" }}
                onClick={() => navigate(`/libur`)}
              >
                <CalendarX size={64} />
                <div className="mt-3" style={{ fontSize: 16 }}>
                  Public Holiday
                </div>
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default MenuMaster;
