import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, ButtonGroup, ToggleButton, Button, Row, Col, Form } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { Search, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { firstDay, mySqlDate } from "../../helper";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const LaporanLembur = () => {
  const [lembur, setLembur] = useState([]);
  const [startDate, setStartDate] = useState(firstDay());
  const [endDate, setEndDate] = useState(mySqlDate(new Date()));

  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Report")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {}, []);

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get(`/lembur/laporan/lembur?start_date=${startDate}&end_date=${endDate}`)
        .then((response) => {
          setLembur(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      if ($.fn.dataTable.isDataTable("#example")) {
        $(document).ready(function () {
          $("#example").DataTable();
        });
      } else {
        $(document).ready(function () {
          $("#example").DataTable({ paging: false });
        });
      }
    }

    loadTable();
  }, [startDate, endDate]);

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-3">
          <Card.Header as="h5">
            <Row>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Start Date
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      required
                      type="date"
                      name="startDate"
                      defaultValue={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="2"></Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    End Date
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      required
                      type="date"
                      name="endDate"
                      defaultValue={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover size="sm" id="example" className="compact">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Detail</th>
                </tr>
              </thead>
              <tbody>
                {lembur.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.nama_karyawan}</td>
                    <td>
                      {dt.total_jam + ":" + dt.total_menit} (
                      <span style={{ fontWeight: "bold", color: "red" }}>
                        {dt.total_jam_approved + ":" + dt.total_menit_approved}
                      </span>
                      )
                    </td>

                    <td style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <ToggleButton
                          type="radio"
                          variant="info"
                          size="sm"
                          onClick={() =>
                            navigate(`/lembur/detail/${dt.id_karyawan}/${startDate}/${endDate}`)
                          }
                          title="Detail"
                        >
                          <Search />
                        </ToggleButton>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default LaporanLembur;
