import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Table, ButtonGroup, ToggleButton, Form, Button, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";
import { firstDay, mySqlDate, ddMMMMyyyy } from "../../helper";
import { useNavigate } from "react-router-dom";
import { Pen, Trash } from "react-bootstrap-icons";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const LemburCreate = () => {
  const [lembur, setLembur] = useState([]);
  const [total_jam, setTotalJam] = useState(0);
  const [total_menit, setTotalMenit] = useState(0);
  const [startDate, setStartDate] = useState(firstDay());
  const [endDate, setEndDate] = useState(mySqlDate(new Date()));
  const [tanggal, setTanggal] = useState(mySqlDate(new Date()));
  const [jam, setJam] = useState(0);
  const [menit, setMenit] = useState(0);
  const [keterangan, setKeterangan] = useState("");
  const [validated, setValidated] = useState(false);
  const [counter, setCounter] = useState(0);

  const navigate = useNavigate();
  const id_karyawan = localStorage.getItem("id_user");

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get(`/lembur/detail/${id_karyawan}?start_date=${startDate}&end_date=${endDate}`)
        .then((response) => {
          setLembur(response.data.data);
          setTotalJam(response.data.total_jam);
          setTotalMenit(response.data.total_menit);
        })
        .catch((err) => {
          console.log(err);
        });

      $(document).ready(function () {
        $("#example").DataTable();
      });
    }
    loadTable();
  }, [startDate, endDate, counter, id_karyawan]);

  function fieldChange(e) {
    switch (e.target.name) {
      case "tanggal":
        setTanggal(e.target.value);
        break;
      case "jam":
        setJam(e.target.value);
        break;
      case "menit":
        setMenit(e.target.value);
        break;
      case "startDate":
        setStartDate(e.target.value);
        break;
      case "endDate":
        setEndDate(e.target.value);
        break;
      default:
        setKeterangan(e.target.value);
    }
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const lembur = {
        id_karyawan: id_karyawan,
        tanggal: tanggal,
        jam: jam,
        menit: menit,
        keterangan: keterangan,
        approved: 0,
      };

      Axios.post(`/lembur`, lembur)
        .then((response) => {
          if (response.data.success) {
            swal("Success!", "Your data has been saved", "success").then(() => {
              setJam(0);
              setMenit(0);
              setKeterangan("");
              setCounter(counter + 1);
            });
          } else {
            swal("Something went wrong", response.data.message, "error");
          }
          console.log(response.data.message);
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    }
    setValidated(true);
  };

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/lembur/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter + 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-3">
          <Card.Header as="h5">Form Input Overtime</Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Date</Form.Label>
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tanggal"
                      value={tanggal}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Hour</Form.Label>
                    <Form.Control
                      type="number"
                      size="sm"
                      name="jam"
                      value={jam}
                      onChange={(e) => fieldChange(e)}
                      min="0"
                      max="24"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="4">
                  <Form.Group className="mb-3">
                    <Form.Label>Minute</Form.Label>
                    <Form.Control
                      type="number"
                      size="sm"
                      name="menit"
                      value={menit}
                      onChange={(e) => fieldChange(e)}
                      min="0"
                      max="60"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={2}
                      name="keterangan"
                      value={keterangan}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="primary" size="sm" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <hr />
        <Card className="mt-3">
          <Card.Header>
            <Row>
              <Col md="3">
                <h5>DATA OVERTIME</h5>
              </Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Start Date
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      required
                      type="date"
                      name="startDate"
                      defaultValue={startDate}
                      onChange={(e) => fieldChange(e)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="1"></Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    End Date
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      required
                      type="date"
                      name="endDate"
                      defaultValue={endDate}
                      onChange={(e) => fieldChange(e)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover size="sm" className="compact">
              <thead>
                <tr>
                  <th>Tanggal</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lembur.length > 0 &&
                  lembur.map((dt, index) => (
                    <tr key={index}>
                      <td>{ddMMMMyyyy(new Date(dt.tanggal))}</td>
                      <td>{dt.jam + ":" + dt.menit}</td>
                      <td>{dt.keterangan}</td>
                      <td style={{ textAlign: "center" }}>
                        <ButtonGroup>
                          <ToggleButton
                            type="radio"
                            variant="warning"
                            size="sm"
                            onClick={() => navigate(`/lembur/edit/${dt.id}`)}
                            disabled={parseInt(dt.approved) === 1 ? true : false}
                            title="Edit"
                          >
                            <Pen />
                          </ToggleButton>
                          <ToggleButton
                            type="radio"
                            variant="danger"
                            size="sm"
                            onClick={() => confirmDelete(dt.id)}
                            disabled={parseInt(dt.approved) === 1 ? true : false}
                            title="Delete"
                          >
                            <Trash />
                          </ToggleButton>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Footer>
            <div style={{ float: "right", fontWeight: "bold" }}>
              Total Duration ({total_jam}:{total_menit})
            </div>
          </Card.Footer>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default LemburCreate;
