import React, { useState } from "react";
import { Form, Button, Card, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "../myAxios";
import swal from "sweetalert";
import LogoText from "../assets/logo-hor.png";
import Footer from "./template/footer";
import { mySqlDate } from "../helper";

const Register = () => {
  const [nama_karyawan, setNamaKaryawan] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [validated, setValidated] = useState(false);
  const [tgl_lahir, setTglLahir] = useState(mySqlDate(new Date()));
  const [tgl_join, setTglJoin] = useState(mySqlDate(new Date()));
  const [tmp_lahir, setTmpLahir] = useState("");
  const [jns_kelamin, setJnsKelamin] = useState(0);
  const [no_ktp, setNoKTP] = useState("");
  const [no_hp, setNoHP] = useState("");
  const [alamat, setAlamat] = useState("");
  const [anggota_keluarga, setAnggotaKeluarga] = useState("");
  const [no_telp_keluarga, setNoTlpKeluarga] = useState("");
  const [hubungan_keluarga, setHubunganKeluarga] = useState("");
  const [pin, setPin] = useState("");

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (password === repeat) {
        const register = {
          nama_karyawan: nama_karyawan,
          email: email,
          password: password,
          aktif: 1,
          tgl_lahir: tgl_lahir,
          tgl_join: tgl_join,
          tmp_lahir: tmp_lahir,
          jns_kelamin: jns_kelamin,
          no_ktp: no_ktp,
          no_hp: no_hp,
          alamat: alamat,
          anggota_keluarga: anggota_keluarga,
          no_telp_keluarga: no_telp_keluarga,
          hubungan_keluarga: hubungan_keluarga,
          pin: pin,
        };

        Axios.post("/register", register)
          .then((response) => {
            console.log(response.data.message);
            if (response.data.success) {
              swal("Success!", "Your account has been created", "success").then(() => {
                navigate("/login");
              });
            } else {
              swal("Warning!", response.data.message, "error");
            }
          })
          .catch((err) => {
            swal("Error!", err, "error").then(() => {});
          });
      } else {
        swal("Warning!", "Password did not match!", "error");
      }
    }
    setValidated(true);
  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div style={{ width: 600 }} className="mb-5">
        <img
          src={LogoText}
          height={128}
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
          alt="Logo"
        />

        <Card>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="nama_karyawan"
                      value={nama_karyawan}
                      onChange={(e) => setNamaKaryawan(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>ID Card No</Form.Label>
                    <Form.Control
                      type="text"
                      name="no_ktp"
                      value={no_ktp}
                      onChange={(e) => setNoKTP(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      name="tgl_lahir"
                      value={tgl_lahir}
                      onChange={(e) => setTglLahir(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Place of Birth</Form.Label>
                    <Form.Control
                      type="text"
                      name="tmp_lahir"
                      value={tmp_lahir}
                      onChange={(e) => setTmpLahir(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="no_hp"
                      value={no_hp}
                      onChange={(e) => setNoHP(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Repeat Password</Form.Label>
                    <Form.Control
                      type="password"
                      name="repeat"
                      value={repeat}
                      onChange={(e) => setRepeat(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Gender</Form.Label>
                    <div>
                      <Form.Check
                        inline
                        type="radio"
                        label="Male"
                        name="jns_kelamin"
                        value={0}
                        onChange={(e) => setJnsKelamin(e.target.value)}
                        checked={parseInt(jns_kelamin) === 0 ? true : false}
                        className="mt-1"
                      />
                      <Form.Check
                        inline
                        type="radio"
                        label="Female"
                        name="jns_kelamin"
                        value={1}
                        onChange={(e) => setJnsKelamin(e.target.value)}
                        checked={parseInt(jns_kelamin) === 1 ? true : false}
                      />
                    </div>
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Date of Join</Form.Label>
                    <Form.Control
                      type="date"
                      name="tgl_join"
                      value={tgl_join}
                      onChange={(e) => setTglJoin(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Form.Group className="mb-3">
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="alamat"
                      value={alamat}
                      onChange={(e) => setAlamat(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Name of Family Member</Form.Label>
                    <Form.Control
                      type="text"
                      name="anggota_keluarga"
                      value={anggota_keluarga}
                      onChange={(e) => setAnggotaKeluarga(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Family's Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="no_telp_keluarga"
                      value={no_telp_keluarga}
                      onChange={(e) => setNoTlpKeluarga(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>Family Relationship</Form.Label>
                    <Form.Control
                      type="text"
                      name="hubungan_keluarga"
                      value={hubungan_keluarga}
                      onChange={(e) => setHubunganKeluarga(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm="6">
                  <Form.Group className="mb-3">
                    <Form.Label>PIN Fingerprint</Form.Label>
                    <Form.Control
                      type="text"
                      name="pin"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                      Placeholder="Contact admin for PIN number"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm="6"></Col>
                <Col sm="6"></Col>
              </Row>

              <div className="d-grid gap-2">
                <Button className="btn btn-primary btn-block" type="submit">
                  Register
                </Button>
              </div>
            </Form>
          </Card.Body>
          <Card.Footer>
            Already have an account?
            <Button
              variant="link"
              onClick={() => {
                navigate("/login");
              }}
            >
              Sign In
            </Button>
          </Card.Footer>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Register;
