import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown, Badge } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";
import LogoHor from "../../assets/ehr_white.png";

const Header = () => {
  const navigate = useNavigate();
  const id_karyawan = localStorage.getItem("id_user");
  const nama_user = localStorage.getItem("nama_karyawan");
  const user_permission = localStorage.getItem("user_permission");
  const [cekProfil, setCekProfil] = useState(true);

  const logout = (event) => {
    event.preventDefault();

    Axios.post("/logout")
      .then((response) => {
        if (response.data.success) {
          localStorage.removeItem("id_user");
          localStorage.removeItem("nama_karyawan");
          localStorage.removeItem("token_hr");
          localStorage.removeItem("email");
          localStorage.removeItem("user_permission");
          console.log(response.data.message);
          navigate("/login");
        } else {
          swal("Something went wrong", `An error occured while saving this data`, "error");
        }
      })
      .catch((err) => {
        swal("Something went wrong", `Request failed: ${err}`, "error");
      });
  };

  useEffect(() => {
    async function cek_profil() {
      await Axios.get(`/karyawan/cek_profil/${id_karyawan}`)
        .then((response) => {
          setCekProfil(response.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    cek_profil();
  }, [id_karyawan]);

  return (
    <Navbar collapseOnSelect expand="lg" style={{ background: "#262d51" }} variant="dark">
      <Container>
        <Navbar.Brand href="/" style={{ marginRight: 20 }}>
          <img src={LogoHor} height={38} alt="Logo" />
        </Navbar.Brand>
        {!cekProfil ? (
          <Navbar.Brand href={`/karyawan/edit/${id_karyawan}`} style={{ marginRight: 20 }}>
            <Badge bg="danger">Complete Your Profile !</Badge>
          </Navbar.Brand>
        ) : (
          ""
        )}

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/" style={{ marginRight: 20, color: "white" }}>
              HOME
            </Nav.Link>

            {user_permission.includes("Master") ? (
              <Nav.Link href="/master" style={{ marginRight: 20, color: "white" }}>
                DATA STORE
              </Nav.Link>
            ) : (
              <></>
            )}

            {user_permission.includes("Time Management") ? (
              <Nav.Link href="/time_management" style={{ marginRight: 20, color: "white" }}>
                TIME MANAGEMENT
              </Nav.Link>
            ) : (
              <></>
            )}

            {user_permission.includes("Report") ? (
              <Nav.Link href="/reports" style={{ marginRight: 20, color: "white" }}>
                REPORTS
              </Nav.Link>
            ) : (
              <></>
            )}
          </Nav>
          <Nav>
            <NavDropdown
              title={<span className="text-light">{nama_user}</span>}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item href={`/karyawan/edit/${id_karyawan}`}>
                Profile Data
              </NavDropdown.Item>
              <NavDropdown.Item href="/change_password">Change Password</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
