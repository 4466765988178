var style = {
  backgroundColor: "#262d51",
  borderTop: "1px solid #E7E7E7",
  textAlign: "center",
  padding: "10px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "40px",
  width: "100%",
  fontSize: 12,
  color: "#fff",
};

var phantom = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

function Footer({ children }) {
  return (
    <div>
      <div style={phantom} />
      <div style={style}>
        Copyright ©2022{" "}
        <a href="https://www.peaksolutions.co.id/" target="_blank" rel="noreferrer">
          Peak Solutions.
        </a>{" "}
        All right reserved.
      </div>
    </div>
  );
}

export default Footer;
