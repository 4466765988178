import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, Button, Spinner } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const PIN = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Master")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadTable() {
      setLoading(true);
      $("#example").DataTable().destroy();
      await Axios.get("/karyawan/get_all_pin")
        .then((response) => {
          setData(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      if ($.fn.dataTable.isDataTable("#example")) {
        $(document).ready(function () {
          $("#example").DataTable();
        });
      } else {
        $(document).ready(function () {
          $("#example").DataTable({ ordering: false });
        });
      }
      setLoading(false);
    }
    loadTable();
  }, []);

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">PIN</Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover size="sm" className="compact">
              <thead>
                <tr>
                  <th>PIN</th>
                  <th>Employee</th>
                </tr>
              </thead>
              <tbody>
                {data.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.pin}</td>
                    <td>{dt.nama_karyawan}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className={loading ? "visually-visible mt-2" : "visually-hidden"}
              />
              <span className={loading ? "visually-visible" : "visually-hidden"}> Loading...</span>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default PIN;
