import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { useNavigate } from "react-router-dom";
import { mySqlDate } from "../../helper";
import validator from "validator";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const AbsenCreate = () => {
  const [tanggal_awal, setTanggalAwal] = useState(mySqlDate(new Date()));
  const [tanggal_akhir, setTanggalAkhir] = useState(mySqlDate(new Date()));
  const [verify, setVerify] = useState(false);
  const [id_jenis_cuti, setIdJenisCuti] = useState(1);
  const [keterangan, setKeterangan] = useState("");
  const [jenis_cuti, setJenisCuti] = useState([]);
  const [detail_cuti, setDetailCuti] = useState([]);
  const [pesan, setPesan] = useState("");
  const [id_karyawan, setIdKaryawan] = useState(0);
  const [karyawan, setKaryawan] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getJenisCuti() {
      await Axios.get("/jenis_cuti/options")
        .then((response) => {
          setJenisCuti(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      await Axios.get("/karyawan/options")
        .then((response) => {
          setKaryawan(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    getJenisCuti();
  }, []);

  function changeTanggalAwal(value) {
    setVerify(false);
    setTanggalAwal(value);
  }

  function changeTanggalAkhir(value) {
    setVerify(false);
    setTanggalAkhir(value);
  }

  function calculate() {
    const cuti = {
      id_karyawan: id_karyawan,
      tanggal_awal: tanggal_awal,
      tanggal_akhir: tanggal_akhir,
    };

    Axios.post(`/jurnal_cuti/calculate_absence`, cuti)
      .then((response) => {
        let data = response.data;
        setDetailCuti(data.data);
        setPesan(data.message);
        setVerify(data.data.length > 0 ? true : false);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });
  }

  function Submit() {
    if (validator.isEmpty(keterangan)) {
      swal("error", `Please input description!`, "error");
      return;
    }

    if (!verify) {
      swal("error", `Please click verify button first!`, "error");
      return;
    }

    const cuti = {
      id_karyawan: id_karyawan,
      id_jenis_cuti: id_jenis_cuti,
      tanggal_awal: tanggal_awal,
      tanggal_akhir: tanggal_akhir,
      durasi: detail_cuti.length,
      keterangan: keterangan,
      status_cuti: 1,
      detail_cuti: detail_cuti,
    };

    Axios.post(`/jurnal_cuti`, cuti)
      .then((response) => {
        if (response.data.success) {
          navigate("/absen");
        } else {
          swal("Something went wrong", `An error occured while saving this data`, "error");
        }
      })
      .catch((err) => {
        console.log(err);
        swal("error", `Request failed: ${err}`, "error");
      });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Create Absence</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Name</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Absence Type</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Form.Select
                      size="sm"
                      name="id_jenis_cuti"
                      value={id_jenis_cuti}
                      onChange={(e) => setIdJenisCuti(e.target.value)}
                    >
                      {jenis_cuti.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Date of absence from</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tanggal_awal"
                      value={tanggal_awal}
                      onChange={(e) => changeTanggalAwal(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm="1">
                    <Form.Label>until</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      type="date"
                      size="sm"
                      name="tanggal_akhir"
                      className="mb-1"
                      value={tanggal_akhir}
                      onChange={(e) => changeTanggalAkhir(e.target.value)}
                    ></Form.Control>
                  </Col>
                  <Col sm="1">
                    <Button variant="primary" size="sm" onClick={calculate}>
                      Calculate
                    </Button>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="4">
                    <span style={{ color: detail_cuti.length === 0 ? "red" : "black" }}>
                      {pesan}
                    </span>
                    <ul>
                      {detail_cuti.map((dt, index) => (
                        <li key={index}>{dt.long_tanggal}</li>
                      ))}
                    </ul>
                  </Col>
                  <Col sm="1"></Col>
                  <Col sm="1">
                    <Form.Label>Description</Form.Label>
                  </Col>
                  <Col sm="3">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="keterangan"
                      value={keterangan}
                      onChange={(e) => setKeterangan(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default AbsenCreate;
