import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";

const ChangePassword = () => {
  const [old_password, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeat, setRepeat] = useState("");
  const [validated, setValidated] = useState(false);

  const navigate = useNavigate();

  const nama_karyawan = localStorage.getItem("nama_karyawan");

  function fieldChange(e) {
    switch (e.target.name) {
      case "old_password":
        setOldPassword(e.target.value);
        break;
      case "password":
        setPassword(e.target.value);
        break;
      default:
        setRepeat(e.target.value);
    }
  }

  const handleSubmit = (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      if (password === repeat) {
        const change_password = {
          old_password: old_password,
          password: password,
        };

        Axios.put(`/karyawan/change/password`, change_password)
          .then((response) => {
            if (response.data.success) {
              swal("Success!", "Your password has been changed", "success").then(() => {
                navigate(-1);
              });
            } else {
              swal("Something went wrong", response.data.message, "error");
            }
            console.log(response.data.message);
          })
          .catch((err) => {
            swal("Something went wrong", `Request failed: ${err}`, "error");
          });
      } else {
        swal("Warning!", "Password did not match!", "error");
      }
    }
    setValidated(true);
  };

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Change Password - {nama_karyawan}</Card.Header>
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Old Password</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="password"
                      size="sm"
                      name="old_password"
                      value={old_password}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>New Password</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="password"
                      size="sm"
                      name="password"
                      value={password}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Repeat Password</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="password"
                      size="sm"
                      name="repeat"
                      value={repeat}
                      onChange={(e) => fieldChange(e)}
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" type="submit">
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default ChangePassword;
