import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, Row, Col, Button, Form, Spinner, Modal } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import validator from "validator";
import ReactPaginate from "react-paginate";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const AttLog = () => {
  const [attlog, setAttlog] = useState([]);
  const [days, setDays] = useState([]);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [id_bulan, setIdBulan] = useState(new Date().getMonth() + 1);
  const [loading, setLoading] = useState(false);
  const [jam, setJam] = useState("");
  const [keterangan, setKeterangan] = useState("");
  const [id, setId] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [page, setPage] = useState(1);
  const [counter, setCounter] = useState(1);
  const [terlambat, setTerlambat] = useState(false);
  const [modalKeterangan, setModalKeterangan] = useState(false);
  const [modalCuti, setModalCuti] = useState(false);

  const navigate = useNavigate();

  const bulan = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  const perPage = 10;

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Master")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  function showModal(data) {
    setId(data.id);
    setKeterangan(data.keterangan);
    setJam(data.jam);
    setTerlambat(data.terlambat);
    data.tipe === 0 ? setModalCuti(true) : setModalKeterangan(true);
  }

  async function update_note() {
    if (!validator.isEmpty(keterangan)) {
      const note = {
        keterangan: keterangan,
        terlambat: terlambat,
      };

      await Axios.put(`/attlog/update_note/${id}`, note)
        .then((response) => {
          if (response.data.success) {
            setCounter(counter + 1);
            swal("Information", `Note has been recorded`, "success");
          } else {
            swal("Something went wrong", `An error occured while saving this data`, "error");
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });

      setModalKeterangan(false);
    }
  }

  const pageChange = (e) => {
    setPage(e.selected + 1);
  };

  async function download_attlog() {
    setLoading(true);
    await Axios.get(`/attlog/download_attlog/${tahun}/${id_bulan}`)
      .then(() => {})
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });

    setCounter(counter + 1);
    setLoading(false);
  }

  useEffect(() => {
    async function loadTable() {
      setLoading(true);
      const attlog = {
        tahun: tahun,
        bulan: id_bulan,
        perPage: perPage,
      };

      await Axios.post("/attlog/attlog_monthly?page=" + page, attlog)
        .then((response) => {
          let data = response.data;
          setAttlog(data.data);
          setDays(data.days);
          setPageCount(data.karyawan.last_page);
          console.log(data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
      setLoading(false);
    }
    loadTable();
  }, [page, tahun, id_bulan, counter]);

  return (
    <div id="page-content-wrapper">
      <Modal show={modalKeterangan} onHide={() => setModalKeterangan(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            as="textarea"
            rows={3}
            name="keterangan"
            value={keterangan}
            onChange={(e) => setKeterangan(e.target.value)}
            required
          />
          <Form.Check
            type="switch"
            className="mt-2"
            label="Deduction"
            onChange={(e) => setTerlambat(e.target.checked)}
            checked={terlambat}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalKeterangan(false)} size="sm">
            Close
          </Button>
          <Button variant="primary" onClick={update_note} size="sm">
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalCuti} onHide={() => setModalCuti(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Absence Note {jam}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {keterangan.split("<br />").map((ket, id) => {
            return <div key={id}>{ket}</div>;
          })}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalCuti(false)} size="sm">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h6">Filter Attendance Log</Card.Header>
          <Card.Body>
            <Row>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Year
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      required
                      size="sm"
                      type="number"
                      name="tahun"
                      defaultValue={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Month
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      name="id_bulan"
                      size="sm"
                      value={id_bulan}
                      onChange={(e) => setIdBulan(e.target.value)}
                    >
                      {bulan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col md="2">
                <Button variant="primary" onClick={download_attlog} size="sm">
                  Update Log
                </Button>
              </Col>
              <Col md="2">
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className={loading ? "visually-visible mt-2" : "visually-hidden"}
                />
                <span className={loading ? "visually-visible" : "visually-hidden"}>
                  {" "}
                  Loading...
                </span>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Table responsive striped bordered hover size="sm" className="compact mt-3">
          <thead>
            <tr>
              <th rowSpan={3} style={{ verticalAlign: "middle", textAlign: "center" }}>
                EMPLOYEE
              </th>
              <th colSpan={days.length} style={{ textAlign: "center" }}>
                {bulan[id_bulan - 1].label.toUpperCase()}
              </th>
            </tr>
            <tr>
              {days.map((day) => (
                <th key={day.tanggal} style={{ textAlign: "center" }}>
                  {day.tanggal}
                </th>
              ))}
            </tr>
            <tr>
              {days.map((day) => (
                <td key={day.tanggal} style={{ fontSize: 12, textAlign: "center" }}>
                  {day.hari}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {attlog.map((dt) => (
              <tr key={dt.id} style={{ fontSize: 12 }}>
                <td
                  style={{
                    minWidth: 160,
                    position: "sticky",
                    left: 0,
                  }}
                  className="table-light"
                >
                  {dt.karyawan}
                </td>
                {dt.scan.map((dt2, index2) => (
                  <td
                    className={dt2.style}
                    key={index2}
                    style={{ minWidth: 30, textAlign: "center" }}
                  >
                    {dt2.item.map((dt3, index3) => (
                      <div key={index3}>
                        <Button
                          variant="link"
                          size="sm"
                          onClick={(e) => showModal(dt3)}
                          style={{ fontSize: 12 }}
                        >
                          {dt3.waktu}
                        </Button>
                      </div>
                    ))}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>

        <Row className="mt-3">
          <Col md="3">
            <ReactPaginate
              nextLabel="next >"
              onPageChange={pageChange}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel="< previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </Col>
          <Col md="6">
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
              className={loading ? "visually-visible mt-2" : "visually-hidden"}
            />
            <span className={loading ? "visually-visible" : "visually-hidden"}> Loading...</span>
          </Col>
        </Row>

        <div className="mt-3">
          <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
            <ArrowLeft /> Back
          </Button>{" "}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AttLog;
