import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import jwt_decode from "jwt-decode";

import Login from "./pages/login";
import Home from "./pages/home";
import Register from "./pages/register";
import ChangePassword from "./pages/karyawan/change_password";

// LEMBUR
import LemburCreate from "./pages/lembur/create";
import LemburEdit from "./pages/lembur/edit";
import LemburDetail from "./pages/lembur/detail";

// MENU
import MasterMenu from "./pages/menu/master";
import TimeManagementMenu from "./pages/menu/time_management";
import ReportsMenu from "./pages/menu/reports";

// KARYAWAN
import KaryawanIndex from "./pages/karyawan/index";
import KaryawanEdit from "./pages/karyawan/edit";
import KaryawanPassword from "./pages/karyawan/password";
import PIN from "./pages/karyawan/pin";

// PERMISSION
import PermissionIndex from "./pages/permission/index";
import PermissionCreate from "./pages/permission/create";
import PermissionEdit from "./pages/permission/edit";

import UserPermission from "./pages/karyawan/user_permission";

import LaporanLembur from "./pages/laporan/lembur";

// JENIS CUTI
import JenisCutiIndex from "./pages/jenis_cuti/index";
import JenisCutiCreate from "./pages/jenis_cuti/create";
import JenisCutiEdit from "./pages/jenis_cuti/edit";

// TIME OFF BALANCE
import TimeOffBalance from "./pages/jurnal_cuti/time_off_balance";
import TimeOffLedger from "./pages/jurnal_cuti/time_off_ledger";

// LIBUR
import LiburIndex from "./pages/libur/index";
import LiburCreate from "./pages/libur/create";
import LiburEdit from "./pages/libur/edit";

// JURNAL CUTI
import ApplyLeave from "./pages/jurnal_cuti/apply_leave.js";
import HalfDayLeave from "./pages/jurnal_cuti/half_day_leave.js";
import LeaveRequest from "./pages/jurnal_cuti/leave_request.js";
import LeaveApplicationForm from "./pages/jurnal_cuti/leave_application_form.js";
import LeaveApplicationPrint from "./pages/jurnal_cuti/leave_application_print.js";

// ATTLOG
import AttLog from "./pages/attendance/attlog.js";
import EmpployeAtt from "./pages/laporan/employee_attendance.js";

// ABSEN
import AbsenIndex from "./pages/absen/index";
import AbsenCreate from "./pages/absen/create";

// TRAVEL LOG
import TravelLogIndex from "./pages/travel_log/index";
import TravelLogCreate from "./pages/travel_log/create";

class App extends Component {
  render() {
    function RequireAuth() {
      let isValid = false;
      if (localStorage.hasOwnProperty("token_hr")) {
        let token = localStorage.getItem("token_hr");
        let decodedToken = jwt_decode(token);
        let currentDate = new Date();

        decodedToken.exp * 1000 < currentDate.getTime() ? (isValid = false) : (isValid = true);
      }

      let location = useLocation();

      if (!isValid) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/login" state={{ from: location }} />;
      }

      return <Outlet />;
    }

    return (
      <Router>
        <Routes>
          <Route path="/login" element={<Login {...this.props} />} />
          <Route path="/register" element={<Register {...this.props} />} />
          <Route
            path="/leave_application_form/:id"
            element={<LeaveApplicationForm {...this.props} />}
          />
          <Route element={<RequireAuth />}>
            <Route path="/change_password" element={<ChangePassword />} />

            <Route path="/master" element={<MasterMenu />} />
            <Route path="/time_management" element={<TimeManagementMenu />} />
            <Route path="/reports" element={<ReportsMenu />} />

            <Route path="/lembur/create" element={<LemburCreate />} />
            <Route path="/lembur/edit/:id" element={<LemburEdit />} />
            <Route
              path="/lembur/detail/:id_karyawan/:startDate/:endDate"
              element={<LemburDetail />}
            />

            <Route path="/karyawan" element={<KaryawanIndex />} />
            <Route path="/karyawan/edit/:id" element={<KaryawanEdit />} />
            <Route path="/karyawan/password/:id" element={<KaryawanPassword />} />
            <Route path="/karyawan/permission/:id" element={<UserPermission />} />
            <Route path="/karyawan/pin" element={<PIN />} />

            <Route path="/permission" element={<PermissionIndex />} />
            <Route path="/permission/create" element={<PermissionCreate />} />
            <Route path="/permission/edit/:id" element={<PermissionEdit />} />

            <Route path="/jenis_cuti" element={<JenisCutiIndex />} />
            <Route path="/jenis_cuti/create" element={<JenisCutiCreate />} />
            <Route path="/jenis_cuti/edit/:id" element={<JenisCutiEdit />} />

            <Route path="/time_off_balance" element={<TimeOffBalance />} />
            <Route path="/time_off_ledger" element={<TimeOffLedger />} />

            <Route path="/laporan/lembur" element={<LaporanLembur />} />

            <Route path="/libur" element={<LiburIndex />} />
            <Route path="/libur/create" element={<LiburCreate />} />
            <Route path="/libur/edit/:id" element={<LiburEdit />} />

            <Route path="/apply_leave" element={<ApplyLeave />} />
            <Route path="/half_day_leave" element={<HalfDayLeave />} />
            <Route path="/leave_request" element={<LeaveRequest />} />
            <Route path="/leave_application_print/:id" element={<LeaveApplicationPrint />} />

            <Route path="/attlog" element={<AttLog />} />
            <Route path="/employee_attendance" element={<EmpployeAtt />} />

            <Route path="/absen" element={<AbsenIndex />} />
            <Route path="/absen/create" element={<AbsenCreate />} />

            <Route path="/travel_log" element={<TravelLogIndex />} />
            <Route path="/travel_log/create" element={<TravelLogCreate />} />

            <Route path="/" element={<Home />} />
          </Route>
        </Routes>
      </Router>
    );
  }
}

export default App;
