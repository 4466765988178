import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, Button, Row, Col, Form, ButtonGroup, ToggleButton } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { ArrowLeft, Trash } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const Absen = () => {
  const [absen, setAbsen] = useState([]);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [id_bulan, setIdBulan] = useState(new Date().getMonth() + 1);
  const [id_karyawan, setIdKaryawan] = useState(0);
  const [karyawan, setKaryawan] = useState([]);
  const [akses, setAkses] = useState(false);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  const bulan = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  useEffect(() => {
    async function loadTable() {
      const user_permission = localStorage.getItem("user_permission");
      if (user_permission.includes("Time Management")) {
        setAkses(true);
      } else {
        setIdKaryawan(localStorage.getItem("id_user"));
      }
    }

    Axios.get("/karyawan/options")
      .then((response) => {
        setKaryawan(response.data.data);
      })
      .catch((err) => {
        swal("error", `Request failed: ${err}`, "error");
      });
    loadTable();
  }, []);

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/jurnal_cuti/cuti/${tahun}/${id_bulan}/${id_karyawan}`)
        .then((response) => {
          setAbsen(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadTable();
  }, [tahun, id_bulan, id_karyawan, counter]);

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/jurnal_cuti/delete_absen/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter - 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Filter</Card.Header>
          <Card.Body>
            <Row>
              <Col md="2">
                <Form.Group as={Row}>
                  <Form.Label column sm={4} style={{ fontSize: 13 }}>
                    Year
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      required
                      type="number"
                      name="tahun"
                      size="sm"
                      defaultValue={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Month
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      name="id_bulan"
                      size="sm"
                      value={id_bulan}
                      onChange={(e) => setIdBulan(e.target.value)}
                    >
                      {bulan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Employee
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                      disabled={!akses}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Header as="h5">Absence</Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover size="sm" className="compact">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Absence</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Duration</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {absen.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.nama_karyawan}</td>
                    <td>{dt.nama_jenis}</td>
                    <td>{dt.tanggal_awal}</td>
                    <td>{dt.tanggal_akhir}</td>
                    <td>{dt.durasi} Day(s)</td>
                    <td style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <ToggleButton
                          type="radio"
                          variant="danger"
                          size="sm"
                          onClick={() => confirmDelete(dt.id)}
                          title="Delete"
                        >
                          <Trash />
                        </ToggleButton>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Button variant="primary" size="sm" onClick={() => navigate("/absen/create")}>
                New
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Absen;
