import axios from "axios";

let token = "";
localStorage.getItem("token_hr")
  ? (token = localStorage.getItem("token_hr").replace(/['"]+/g, ""))
  : (token = "");

const $axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

$axios.interceptors.request.use(
  function (config) {
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default $axios;
