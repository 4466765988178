import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "react-bootstrap";

export function numToBool(value) {
  let iBool;
  value === 1 ? (iBool = true) : (iBool = false);
  return iBool;
}

export function filter(arr, criteria) {
  return arr.filter(function (obj) {
    return Object.keys(criteria).every(function (c) {
      return obj[c] === criteria[c];
    });
  });
}

export function firstDay() {
  let date = new Date();
  let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);

  let sDay = "0" + firstDay.getDate().toString();

  let iMonth = firstDay.getMonth() + 1;
  let sMonth = iMonth.toString();

  if (iMonth < 10) sMonth = "0" + iMonth.toString();

  return date.getFullYear().toString() + "-" + sMonth + "-" + sDay;
}

export function mySqlDate(date) {
  return new Date(new Date(date.toISOString()).getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 19)
    .split("T")[0];
}

export function ddMMMMyyyy(date) {
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let mo = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);

  return `${da} ${mo} ${ye}`;
}

function checkTime(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

export function ddMMMMyyyyHHmm(date) {
  let ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);
  let mo = new Intl.DateTimeFormat("en", { month: "long" }).format(date);
  let da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  let hh = date.getHours();
  let mm = date.getMinutes();
  mm = checkTime(mm);

  return `${da} ${mo} ${ye} ${hh}:${mm}`;
}

export const page = {
  background: "white",
  display: "block",
  margin: "0 auto",
  marginBottom: "0.5cm",
  width: "21cm",
  height: "29.7cm",

  lineHeight: "1.6",
  color: "#000",
  padding: 40,
};

export const left50 = {
  float: "left",
  width: "50%",
  padding: "5px",
};

export const right50 = {
  float: "right",
  width: "50%",
  padding: "5px",
};

export const left70 = {
  float: "left",
  width: "70%",
  padding: "5px",
};

export const right30 = {
  float: "right",
  width: "30%",
  padding: "5px",
};

export const row = {
  content: "",
  display: "table",
  clear: "both",
  width: "100%",
};

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button variant="success" size="sm" onClick={(e) => exportToCSV(apiData, fileName)}>
      Export
    </Button>
  );
};
