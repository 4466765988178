import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button } from "react-bootstrap";
import swal from "sweetalert";
import validator from "validator";
import Axios from "../../myAxios";

const JenisCutiEdit = () => {
  const [nama_jenis, setNamaJenis] = useState("");
  const [nama_alias, setNamaAlias] = useState("");
  const [durasi, setDurasi] = useState(0);
  const [cuti, setCuti] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Time Management")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadData() {
      await Axios.get(`/jenis_cuti/${id}`)
        .then((response) => {
          let data = response.data.data;
          setNamaJenis(data.nama_jenis);
          setNamaAlias(data.nama_alias);
          setDurasi(data.durasi);
          setCuti(data.cuti);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadData();
  }, [id]);

  function Submit() {
    if (!validator.isEmpty(nama_jenis)) {
      const jenis_cuti = {
        nama_jenis: nama_jenis,
        nama_alias: nama_alias,
        durasi: durasi,
        cuti: cuti,
      };

      Axios.put(`/jenis_cuti/${id}`, jenis_cuti)
        .then((response) => {
          if (response.data.success) {
            navigate("/jenis_cuti");
          } else {
            swal("Something went wrong", `An error occured while saving this data`, "error");
          }
        })
        .catch((err) => {
          swal("Something went wrong", `Request failed: ${err}`, "error");
        });
    } else {
      swal("Warning!", "Please fill in all the required fields!", "error");
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Edit Leave Category</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Name</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="nama_jenis"
                      value={nama_jenis}
                      onChange={(e) => setNamaJenis(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Alias</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="text"
                      size="sm"
                      name="nama_alias"
                      value={nama_alias}
                      onChange={(e) => setNamaAlias(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Duration</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      size="sm"
                      name="durasi"
                      value={durasi}
                      onChange={(e) => setDurasi(e.target.value)}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Status</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Check
                      type="switch"
                      label="Leave"
                      onChange={(e) => setCuti(e.target.checked)}
                      checked={cuti}
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default JenisCutiEdit;
