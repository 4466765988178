import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { useNavigate } from "react-router-dom";
import { Stopwatch, PersonCheck, Journal } from "react-bootstrap-icons";

const MenuReports = () => {
  const navigate = useNavigate();
  const user_permission = localStorage.getItem("user_permission");

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Row className="mt-3 ">
          {user_permission.includes("Overtime") ? (
            <Col sm="2">
              <div className="d-grid gap-2">
                <Button
                  variant="danger"
                  size="lg"
                  style={{ padding: 30, margin: 5, color: "white" }}
                  onClick={() => navigate(`/laporan/lembur`)}
                >
                  <Stopwatch size={64} />
                  <div className="mt-3" style={{ fontSize: 16 }}>
                    Overtime
                  </div>
                </Button>
              </div>
            </Col>
          ) : (
            <></>
          )}

          {user_permission.includes("Time Management") ? (
            <Col sm="2">
              <div className="d-grid gap-2">
                <Button
                  variant="danger"
                  size="lg"
                  style={{ padding: 30, margin: 5, color: "white" }}
                  onClick={() => navigate(`/employee_attendance`)}
                >
                  <PersonCheck size={64} />
                  <div className="mt-3" style={{ fontSize: 15 }}>
                    Employee Att
                  </div>
                </Button>
              </div>
            </Col>
          ) : (
            <></>
          )}

          {user_permission.includes("Time Management") ? (
            <Col sm="2">
              <div className="d-grid gap-2">
                <Button
                  variant="danger"
                  size="lg"
                  style={{ padding: 30, margin: 5, color: "white" }}
                  onClick={() => navigate(`/time_off_ledger`)}
                >
                  <Journal size={64} />
                  <div className="mt-3" style={{ fontSize: 15 }}>
                    Leave Ledger
                  </div>
                </Button>
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </div>
      <Footer />
    </div>
  );
};

export default MenuReports;
