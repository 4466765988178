import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import {
  Card,
  Table,
  Button,
  Row,
  Col,
  Form,
  ButtonGroup,
  DropdownButton,
  Dropdown,
  Badge,
} from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const TravelLog = () => {
  const [travel, setTravel] = useState([]);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [id_bulan, setIdBulan] = useState(new Date().getMonth() + 1);
  const [id_karyawan, setIdKaryawan] = useState(localStorage.getItem("id_user"));
  const [karyawan, setKaryawan] = useState([]);
  const [akses, setAkses] = useState(false);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  const bulan = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  useEffect(() => {
    async function loadTable() {
      const user_permission = localStorage.getItem("user_permission");

      if (user_permission.includes("Time Management")) {
        setAkses(true);
      } else {
        setAkses(false);
      }

      await Axios.get("/karyawan/options")
        .then((response) => {
          setKaryawan(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }

    loadTable();
  }, []);

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/travel_log/${tahun}/${id_bulan}/${id_karyawan}`)
        .then((response) => {
          setTravel(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadTable();
  }, [tahun, id_bulan, id_karyawan, counter]);

  function confirmDelete(id, status_cuti) {
    if (parseInt(status_cuti) === 0) {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this record!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          Axios.delete(`/jurnal_cuti/delete_absen/${id}`).then((response) => {
            if (response.data.success) {
              swal("Delete Confirmation!", "Data deleted successfully", "success");
              setCounter(counter - 1);
            }
          });
        } else {
          swal("Your record is safe!");
        }
      });
    } else {
      swal("Delete Confirmation!", "Cannot delete approved leave", "error");
    }
  }

  function updateStatus(id, status_cuti) {
    let status = 0;
    if (parseInt(status) === parseInt(status_cuti)) {
      status = 1;
      console.log(true);
    } else {
      console.log(false);
    }

    Axios.post(`/jurnal_cuti/update_status?id=${id}&status_cuti=${status}`)
      .then((response) => {
        if (response.data.success) {
          setCounter(counter - 1);
        }
      })
      .catch((err) => {
        swal("Something went wrong", `Request failed: ${err}`, "error");
      });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Filter</Card.Header>
          <Card.Body>
            <Row>
              <Col md="2">
                <Form.Group as={Row}>
                  <Form.Label column sm={4} style={{ fontSize: 13 }}>
                    Year
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      required
                      type="number"
                      name="tahun"
                      size="sm"
                      defaultValue={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md="3">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Month
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Select
                      name="id_bulan"
                      size="sm"
                      value={id_bulan}
                      onChange={(e) => setIdBulan(e.target.value)}
                    >
                      {bulan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={3} style={{ fontSize: 13 }}>
                    Employee
                  </Form.Label>
                  <Col sm={9}>
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                      disabled={!akses}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Header as="h5">Travel Log</Card.Header>
          <Card.Body>
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              className="compact"
              style={{ minHeight: "210px" }}
            >
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Employee</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {travel.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.tanggal_awal}</td>
                    <td>{dt.nama_karyawan}</td>
                    <td>
                      {dt.keterangan.split("<br />").map((ket, id) => {
                        return <div key={id}>{ket}</div>;
                      })}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {parseInt(dt.status_cuti) === 0 ? (
                        <Badge bg="secondary">Waiting</Badge>
                      ) : (
                        <Badge bg="success">Approved</Badge>
                      )}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <DropdownButton as={ButtonGroup} variant="secondary" size="sm" title="">
                        <Dropdown.Item
                          onClick={() => window.open(`/leave_application_form/${dt.id}`, "_blank")}
                          style={{ fontSize: 14 }}
                          disabled={!akses}
                        >
                          Form
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => window.open(`/leave_application_print/${dt.id}`, "_blank")}
                          style={{ fontSize: 14 }}
                        >
                          Print
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() => updateStatus(dt.id, dt.status_cuti)}
                          style={{ fontSize: 14 }}
                          disabled={!akses}
                        >
                          {parseInt(dt.status_cuti) === 0 ? "Approve" : "Cancel Approve"}
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => confirmDelete(dt.id, dt.status_cuti)}
                          style={{ fontSize: 14 }}
                        >
                          Delete
                        </Dropdown.Item>
                      </DropdownButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Button variant="primary" size="sm" onClick={() => navigate("/travel_log/create")}>
                New
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default TravelLog;
