import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, ButtonGroup, ToggleButton, Button, Row, Col, Form } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { Pen, Trash, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const Libur = () => {
  const [libur, setLibur] = useState([]);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Time Management")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get("/libur?tahun=" + tahun)
        .then((response) => {
          setLibur(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      if ($.fn.dataTable.isDataTable("#example")) {
        $(document).ready(function () {
          $("#example").DataTable();
        });
      } else {
        $(document).ready(function () {
          $("#example").DataTable({ ordering: false });
        });
      }
    }
    loadTable();
  }, [counter, tahun]);

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/libur/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter - 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Filter</Card.Header>
          <Card.Body>
            <Row>
              <Col md="4">
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>
                    Year
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      required
                      type="number"
                      name="tahun"
                      defaultValue={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Header as="h5">Public Holiday</Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover size="sm" className="compact" id="example">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {libur.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.long_tanggal_libur}</td>
                    <td>{dt.nama_libur}</td>
                    <td style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <ToggleButton
                          type="radio"
                          variant="warning"
                          size="sm"
                          onClick={() => navigate(`/libur/edit/${dt.id}`)}
                          title="Edit"
                        >
                          <Pen />
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          variant="danger"
                          size="sm"
                          onClick={() => confirmDelete(dt.id)}
                          title="Delete"
                        >
                          <Trash />
                        </ToggleButton>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Button variant="primary" size="sm" onClick={() => navigate("/libur/create")}>
                New
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Libur;
