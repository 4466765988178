import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button, Table, ButtonGroup, ToggleButton } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";
import { Trash } from "react-bootstrap-icons";

const UserPermission = () => {
  const [user_permission, setUserPermission] = useState([]);
  const [permission, setPermission] = useState([]);
  const [id_permission, setIdPermission] = useState(0);
  const [counter, setCounter] = useState(0);
  const [nama_karyawan, setNamaKaryawan] = useState("");

  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Permission")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function optionPermission() {
      await Axios.get(`/permission/options`)
        .then((response) => {
          let data = response.data.data;
          setPermission(data);
          setIdPermission(data[0].value);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    optionPermission();
  }, []);

  useEffect(() => {
    async function loadTable() {
      await Axios.get(`/user_permission/${id}`)
        .then((response) => {
          let data = response.data.data;
          setUserPermission(data);
          setNamaKaryawan(response.data.nama_karyawan);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadTable();
  }, [counter, id]);

  function fieldChange(e) {
    setIdPermission(e.target.value);
  }

  function Submit() {
    Axios.post(`/user_permission/${id}`, { id_permission: id_permission })
      .then((response) => {
        if (response.data.success) {
          swal("Success", response.data.message, "success");
          setCounter(counter + 1);
        } else {
          swal("Something went wrong", response.data.message, "error");
        }
        console.log(response.data.message);
      })
      .catch((err) => {
        swal("Something went wrong", `Request failed: ${err}`, "error");
      });
  }

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/user_permission/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
            setCounter(counter + 1);
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-2">
          <Card.Header as="h5">Employee Permission - {nama_karyawan}</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Permission</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Select
                      size="sm"
                      name="id_permission"
                      value={id_permission}
                      onChange={(e) => fieldChange(e)}
                    >
                      {permission.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                  <Col sm="4">
                    <Button variant="primary" size="sm" onClick={Submit}>
                      Insert
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
              <Row>
                <Col sm="12">
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    size="sm"
                    id="example"
                    className="compact"
                  >
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {user_permission.map((dt, index) => (
                        <tr key={index}>
                          <td>{dt.nama_permission}</td>
                          <td style={{ textAlign: "center" }}>
                            <ButtonGroup>
                              <ToggleButton
                                type="radio"
                                variant="danger"
                                size="sm"
                                onClick={() => confirmDelete(dt.id)}
                              >
                                <Trash />
                              </ToggleButton>
                            </ButtonGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default UserPermission;
