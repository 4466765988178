import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Form, Row, Col, Button, Table } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";

const TimeOffBalance = () => {
  const [karyawan, setKaryawan] = useState([]);
  const [id_karyawan, setIdKaryawan] = useState(0);
  const [tahun, setTahun] = useState(new Date().getFullYear());
  const [deduksi, setDeduksi] = useState(0);
  const [jurnal_cuti, setJurnalCuti] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Time Management")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadTable() {
      await Axios.get("/karyawan/options_all")
        .then((response) => {
          setKaryawan(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });
    }
    loadTable();
  }, []);

  function Submit() {
    const balance = {
      tahun: tahun,
      id_karyawan: id_karyawan,
      deduksi: deduksi,
    };

    Axios.post(`/jurnal_cuti/simulate_by_employee`, balance)
      .then((response) => {
        if (response.data.success) {
          setJurnalCuti(response.data.data);
          console.log(response.data.data);
        } else {
          console.log(response.data.message);
          swal("Something went wrong", response.data.message, "error");
        }
      })
      .catch((err) => {
        swal("Something went wrong", `Request failed: ${err}`, "error");
      });
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-3">
          <Card.Header as="h5">Leave Balance</Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Year</Form.Label>
                  </Col>
                  <Col sm="2">
                    <Form.Control
                      type="number"
                      size="sm"
                      name="tahun"
                      value={tahun}
                      onChange={(e) => setTahun(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Employee</Form.Label>
                  </Col>
                  <Col sm="4">
                    <Form.Select
                      size="sm"
                      name="id_karyawan"
                      value={id_karyawan}
                      onChange={(e) => setIdKaryawan(e.target.value)}
                    >
                      {karyawan.map((dt, index) => (
                        <option key={index} value={dt.value}>
                          {dt.label}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col sm="2">
                    <Form.Label>Deduction</Form.Label>
                  </Col>
                  <Col sm="2">
                    <Form.Control
                      type="number"
                      size="sm"
                      name="deduksi"
                      value={deduksi}
                      onChange={(e) => setDeduksi(e.target.value)}
                    ></Form.Control>
                  </Col>
                </Row>
              </Form.Group>

              <Row>
                <Col sm="12" style={{ textAlign: "right" }}>
                  <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                    Cancel
                  </Button>{" "}
                  <Button variant="primary" size="sm" onClick={Submit}>
                    Top Up Balance
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
        <Card className="mt-2">
          <Card.Body>
            <Table responsive striped bordered hover size="sm" className="compact">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Effective Date</th>
                  <th>Expired Date</th>
                  <th>Time Off</th>
                  <th>Duration</th>
                </tr>
              </thead>
              <tbody>
                {jurnal_cuti.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.nama_karyawan}</td>
                    <td>{dt.tanggal_awal}</td>
                    <td>{dt.tanggal_akhir}</td>
                    <td>
                      {dt.detail.map((dt2, index2) => (
                        <div key={index2}>{dt2.nama_jenis}</div>
                      ))}
                    </td>
                    <td>
                      {dt.detail.map((dt2, index2) => (
                        <div key={index2}>{dt2.durasi}</div>
                      ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default TimeOffBalance;
