import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Header from "../template/header";
import Footer from "../template/footer";
import { Card, Table, ButtonGroup, ToggleButton, Button, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import Axios from "../../myAxios";
import { useNavigate, useParams } from "react-router-dom";
import { Pen, Trash, ArrowLeft, Check } from "react-bootstrap-icons";
import { ExportToExcel, ddMMMMyyyy } from "../../helper";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const LemburDetail = () => {
  const [lembur, setLembur] = useState([]);
  const [total_jam, setTotalJam] = useState(0);
  const [total_menit, setTotalMenit] = useState(0);
  const [total_jam_approved, setTotalJamApproved] = useState(0);
  const [total_menit_approved, setTotalMenitApproved] = useState(0);
  const [counter, setCounter] = useState(0);
  const [excel, setExcel] = useState([]);

  const navigate = useNavigate();
  const { id_karyawan, startDate, endDate } = useParams();

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get(`/lembur/detail/${id_karyawan}?start_date=${startDate}&end_date=${endDate}`)
        .then((response) => {
          let data = response.data.data;
          setLembur(data);
          setTotalJam(response.data.total_jam);
          setTotalMenit(response.data.total_menit);
          setTotalJamApproved(response.data.total_jam_approved);
          setTotalMenitApproved(response.data.total_menit_approved);
          setExcel(response.data.excel);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      $(document).ready(function () {
        $("#example").DataTable();
      });
    }
    loadTable();
  }, [startDate, endDate, id_karyawan, counter]);

  function confirmDelete(id) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        Axios.delete(`/lembur/${id}`).then((response) => {
          if (response.data.success) {
            swal("Delete Confirmation!", "Data deleted successfully", "success");
          }
        });
      } else {
        swal("Your record is safe!");
      }
    });
  }

  const approve_all = () => {
    Axios.put(`/lembur/approve_all/${id_karyawan}?start_date=${startDate}&end_date=${endDate}`)
      .then((response) => {
        if (response.data.success) {
          swal("Success!", "Data approved successfully", "success").then(() => {
            setCounter(counter + 1);
          });
        } else {
          swal("Something went wrong", response.data.message, "error");
        }
        console.log(response.data.message);
      })
      .catch((err) => {
        swal("Something went wrong", `Request failed: ${err}`, "error");
      });
  };

  const approve = (id) => {
    Axios.put(`/lembur/approve/${id}`)
      .then((response) => {
        if (response.data.success) {
          swal("Success!", "Data approved successfully", "success").then(() => {
            setCounter(counter + 1);
          });
        } else {
          swal("Something went wrong", response.data.message, "error");
        }
        console.log(response.data.message);
      })
      .catch((err) => {
        swal("Something went wrong", `Request failed: ${err}`, "error");
      });
  };

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid">
        <Card className="mt-3">
          <Card.Header>
            Detail Overtime - {lembur.length > 0 ? lembur[0].nama_karyawan : ""}
          </Card.Header>
          <Card.Body>
            <Table
              responsive
              striped
              bordered
              hover
              size="sm"
              className="compact"
              id="table-to-xls"
            >
              <thead>
                <tr>
                  <th>Tanggal</th>
                  <th>Duration</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lembur.length > 0 &&
                  lembur.map((dt, index) => (
                    <tr key={index}>
                      <td>{ddMMMMyyyy(new Date(dt.tanggal))}</td>
                      <td>{dt.jam + ":" + dt.menit}</td>
                      <td>{dt.keterangan}</td>
                      <td>
                        {parseInt(parseInt(dt.approved)) === 0 ? "Waiting Approval" : "Approved"}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <ButtonGroup>
                          <ToggleButton
                            type="radio"
                            variant="success"
                            size="sm"
                            onClick={() => approve(dt.id)}
                            disabled={parseInt(dt.approved) === 1 ? true : false}
                            title="Approve"
                          >
                            <Check />
                          </ToggleButton>
                          <ToggleButton
                            type="radio"
                            variant="warning"
                            size="sm"
                            onClick={() => navigate(`/lembur/edit/${dt.id}`)}
                            disabled={parseInt(dt.approved) === 1 ? true : false}
                            title="Edit"
                          >
                            <Pen />
                          </ToggleButton>
                          <ToggleButton
                            type="radio"
                            variant="danger"
                            size="sm"
                            onClick={(e) => confirmDelete(dt.id)}
                            disabled={parseInt(dt.approved) === 1 ? true : false}
                            title="Delete"
                          >
                            <Trash />
                          </ToggleButton>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            <Row>
              <Col sm="6">
                <Button variant="primary" size="sm" onClick={approve_all}>
                  Approve All
                </Button>{" "}
                <ExportToExcel
                  apiData={excel}
                  fileName={lembur.length > 0 ? lembur[0].nama_karyawan : "Overtime"}
                />
              </Col>
              <Col sm="6">
                <div style={{ float: "right", fontWeight: "bold" }}>
                  Duration ({total_jam}:{total_menit}) &emsp; Duration Approved (
                  {total_jam_approved}:{total_menit_approved})
                </div>
              </Col>
            </Row>
          </Card.Body>
          <Card.Footer>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
            </div>
          </Card.Footer>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default LemburDetail;
