import "../../css/jquery.dataTables.css";
import React, { useState, useEffect } from "react";
import Axios from "../../myAxios";
import swal from "sweetalert";
import { Card, Table, ButtonGroup, ToggleButton, Button } from "react-bootstrap";
import Header from "../template/header";
import Footer from "../template/footer";
import { Pen, Trash, ArrowLeft } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

const $ = require("jquery");
$.Datatable = require("datatables.net");

const Permission = () => {
  const [permission, setPermission] = useState([]);
  const [counter, setCounter] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Permission")) {
      swal("Warning!", "You don't have permission to access!", "error").then(function () {
        navigate(-1);
      });
    }
  }, [navigate]);

  useEffect(() => {
    async function loadTable() {
      $("#example").DataTable().destroy();
      await Axios.get("/permission")
        .then((response) => {
          setPermission(response.data.data);
        })
        .catch((err) => {
          swal("error", `Request failed: ${err}`, "error");
        });

      $(document).ready(function () {
        $("#example").DataTable();
      });
    }
    loadTable();
  }, [counter]);

  function confirmDelete(id) {
    const user_permission = localStorage.getItem("user_permission");
    if (!user_permission.includes("Delete")) {
      swal("Warning!", "You don't have permission to access!", "error");
    } else {
      swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this record!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          Axios.delete(`/permission/${id}`).then((response) => {
            if (response.data.success) {
              swal("Delete Confirmation!", "Data deleted successfully", "success");
              setCounter(counter - 1);
            }
          });
        } else {
          swal("Your record is safe!");
        }
      });
    }
  }

  return (
    <div id="page-content-wrapper">
      <Header />
      <div className="container-fluid ">
        <Card className="mt-2">
          <Card.Header as="h5">Permission</Card.Header>
          <Card.Body>
            <Table responsive striped bordered hover size="sm" id="example" className="compact">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {permission.map((dt, index) => (
                  <tr key={index}>
                    <td>{dt.nama_permission}</td>
                    <td>{dt.keterangan}</td>
                    <td style={{ textAlign: "center" }}>
                      <ButtonGroup>
                        <ToggleButton
                          type="radio"
                          variant="warning"
                          size="sm"
                          onClick={() => navigate(`/permission/edit/${dt.id}`)}
                          title="Edit"
                        >
                          <Pen />
                        </ToggleButton>
                        <ToggleButton
                          type="radio"
                          variant="danger"
                          size="sm"
                          onClick={() => confirmDelete(dt.id)}
                          title="Delete"
                        >
                          <Trash />
                        </ToggleButton>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="mt-3">
              <Button variant="outline-primary" size="sm" onClick={() => navigate(-1)}>
                <ArrowLeft /> Back
              </Button>{" "}
              <Button variant="primary" size="sm" onClick={() => navigate("/permission/create")}>
                New
              </Button>
            </div>
          </Card.Body>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Permission;
