import React, { useState } from "react";
import { Form, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "../myAxios";
import swal from "sweetalert";
import validator from "validator";
import LogoText from "../assets/logo-hor.png";
import Footer from "./template/footer";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  function fieldChange(field, e) {
    switch (field) {
      case "email":
        setEmail(e.target.value);
        break;
      default:
        setPassword(e.target.value);
    }
  }

  async function Submit() {
    if (validator.isEmail(email) && !validator.isEmpty(password)) {
      const auth = {
        email: email,
        password: password,
      };

      await Axios.post("/login", auth)
        .then((response) => {
          localStorage.setItem("id_user", response.data.id);
          localStorage.setItem("nama_karyawan", response.data.nama_karyawan);
          localStorage.setItem("email", response.data.email);
          localStorage.setItem("token_hr", JSON.stringify(response.data.token));
          localStorage.setItem("user_permission", response.data.user_permission);
          navigate("/");
          window.location.reload();
        })
        .catch((err) => {
          swal("Login Failed!", "Please ensure the username and password are valid.", "error");
          console.log(err);
        });
    } else {
      swal("Warning!", "Please fill in all the required fields!", "error");
    }
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      Submit();
    }
  };

  return (
    <div className="d-flex align-items-center justify-content-center" style={{ height: 700 }}>
      <div style={{ width: 360 }}>
        <img
          src={LogoText}
          height={192}
          style={{ display: "block", marginLeft: "auto", marginRight: "auto", marginBottom: 20 }}
          alt="Logo"
        />
        <Card>
          <Card.Body>
            <Form className="mt-2 ">
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => fieldChange("email", e)}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => fieldChange("password", e)}
                  onKeyPress={handleKeyPress}
                />
              </Form.Group>
              <div className="d-grid gap-2">
                <Button className="btn btn-primary btn-block" onClick={Submit}>
                  Login
                </Button>
              </div>
            </Form>
          </Card.Body>
          <Card.Footer>
            Don't have an account?
            <Button
              variant="link"
              onClick={() => {
                navigate("/register");
              }}
            >
              Register
            </Button>
          </Card.Footer>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
